<template>
  <div>
    <vx-card title="Les administrateurs" class="mb-4">
      <div slot="no-body">
        <div class="aab-vie-content">
          <div>
            <div>
              <vs-prompt
                title="Exportation"
                class="export-options"
                @cancel="clearFields"
                @accept="exportToData"
                accept-text="Valider"
                cancel-text="Annuler"
                @close="clearFields"
                :active.sync="activePrompt"
              >
                <vs-input
                  v-model="fileName"
                  placeholder="Entrez le nom du fichier..."
                  class="w-full"
                />
                <multiselect
                  v-model="selectedFormat"
                  :options="formats"
                  class="my-4"
                  placeholder="--Choisir--"
                  :selectLabel="global.selectLabel"
                  :selectedLabel="global.selectedLabel"
                  :deselectLabel="global.deselectLabel"
                >
                  <template v-slot:noOptions>
                    <span>{{ global.selectNotFoundData }}</span>
                  </template>
                </multiselect>

                <div class="flex">
                  <span class="mr-4">Largeur automatique de cellule:</span>
                  <vs-switch v-model="cellAutoWidth"
                    >Largeur automatique de cellule</vs-switch
                  >
                </div>
              </vs-prompt>
              <div
                class="
                  flex flex-wrap
                  data-list-btn-container
                  aab-vie-btn-action-grp
                "
              >
                <div class="aab-vie-btn-action">
                  <vs-button
                    v-if="checkUserRole('users').create"
                    @click="popupAddUserActive = true"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    >Ajouter</vs-button
                  >
                  <vx-tooltip
                    color="primary"
                    :title="role.notAllowTitle"
                    :text="role.notAllowText"
                    v-else
                  >
                    <vs-button
                      :disabled="true"
                      color="primary"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                      >Ajouter</vs-button
                    >
                  </vx-tooltip>
                </div>
                
                <div class="ml-4 aab-vie-btn-action">
                  <div>
                    <vs-dropdown class="dd-actions cursor-pointer">
                      <div
                        class="
                          p-3
                          shadow-drop
                          rounded-lg
                          d-theme-dark-bg
                          cursor-pointer
                          flex
                          items-center
                          justify-center
                          text-lg
                          font-medium
                          w-32 w-full
                        "
                      >
                        <span class="mr-2">Afficher {{ limit }}</span>
                        <feather-icon
                          icon="ChevronDownIcon"
                          svgClasses="h-4 w-4"
                        />
                      </div>
                      <vs-dropdown-menu>
                        <vs-dropdown-item
                          :key="i"
                          v-for="(r, i) in rowsTable"
                          @click="limit = r"
                        >
                          <span class="flex items-center">
                            <span>{{ r }}</span>
                          </span>
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </div>
                </div>
                <div class="ml-4 aab-vie-btn-action">
                  <vs-dropdown class="dd-actions cursor-pointer ml-4">
                    <div
                      class="
                        p-3
                        shadow-drop
                        rounded-lg
                        d-theme-dark-bg
                        cursor-pointer
                        flex
                        items-center
                        justify-center
                        text-lg
                        font-medium
                        w-32 w-full
                      "
                    >
                      <span class="mr-2">Actions</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="activePrompt = true">
                        <span class="flex items-center">
                          <feather-icon
                            icon="PrinterIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Exporter</span>
                        </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item
                        v-if="checkUserRole('users').delete"
                        :disabled="userSelected.length == 0"
                        @click="checkRemoveMultipleUser"
                      >
                        <span class="flex items-center">
                          <feather-icon
                            icon="Trash2Icon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Supprimer la sélection</span>
                        </span>
                      </vs-dropdown-item>
                      <vx-tooltip
                        color="primary"
                        :title="role.notAllowTitle"
                        :text="role.notAllowText"
                        v-else
                      >
                        <vs-dropdown-item :disabled="true">
                          <span class="flex items-center">
                            <feather-icon
                              icon="Trash2Icon"
                              svgClasses="h-4 w-4"
                              class="mr-2"
                            />
                            <span>Supprimer la sélection</span>
                          </span>
                        </vs-dropdown-item>
                      </vx-tooltip>
                      <vs-dropdown-item
                        v-if="checkUserRole('broker').send"
                        :disabled="userSelected.length == 0"
                        @click="checkSentPublipostage"
                      >
                        <span class="flex items-center">
                          <feather-icon
                            icon="MailIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Publipostage</span>
                        </span>
                      </vs-dropdown-item>
                      <vx-tooltip
                        color="primary"
                        :title="role.notAllowTitle"
                        :text="role.notAllowText"
                        v-else
                      >
                        <vs-dropdown-item :disabled="true">
                          <span class="flex items-center">
                            <feather-icon
                              icon="MailIcon"
                              svgClasses="h-4 w-4"
                              class="mr-2"
                            />
                            <span>Publipostage</span>
                          </span>
                        </vs-dropdown-item>
                      </vx-tooltip>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
              <div class>
                <div class="w-auto" v-if="loading">
                  <clip-loader :loading="loading" color="primary"></clip-loader>
                </div>
                <div v-else>
                  <div v-if="allUsers.list.length !== 0">
                    <vs-table
                      multiple
                      @dblSelection="adminSelectedDouble"
                      :data="allUsers.list"
                      v-model="userSelected"
                      search
                      stripe
                      pagination
                      :max-items="limit != 'Tout' ? limit : total"
                      noDataText="Aucun résultat trouvé"
                    >
                      <template slot="thead">
                        <vs-th>N°</vs-th>
                        <vs-th sort-key="type">Profil</vs-th>
                        <vs-th sort-key="identity">Identité</vs-th>
                        <vs-th sort-key="email">Email</vs-th>
                        <vs-th>Actions</vs-th>
                      </template>
                      <template slot-scope="{ data }">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                        >
                          <vs-td :data="data[indextr].id">{{
                            indextr + 1
                          }}</vs-td>
                          <vs-td :data="data[indextr].type">{{
                            displayType(data[indextr].type)
                          }}</vs-td>

                          <vs-td :data="data[indextr].identity">{{
                            data[indextr].identity
                          }}</vs-td>
                          <vs-td :data="data[indextr].email">{{
                            data[indextr].email
                          }}</vs-td>
                          <vs-td :data="data[indextr].id">
                            <div class="inline-flex" @click.stop>
                              <vx-tooltip
                                color="primary"
                                :title="lastLogin(data[indextr]).title"
                                :text="lastLogin(data[indextr]).text"
                              >
                                <div class="cursor-pointer">
                                  <feather-icon
                                    icon="FlagIcon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>

                              <vs-dropdown
                                class="dd-actions cursor-pointer ml-6"
                              >
                                <div class="cursor-pointer">
                                  <feather-icon
                                    icon="AlignJustifyIcon"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                                <vs-dropdown-menu class="aab-vie-dropdown">
                                  <div>
                                    <vs-dropdown-item
                                      v-if="checkUserRole('users').update"
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="checkUpdateUser(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="Edit2Icon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Modifier</span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              class="text-primary"
                                              icon="Edit2Icon"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span>Modifier</span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>

                                  <div>
                                    <vs-dropdown-item
                                      v-if="checkUserRole('users').update"
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="
                                          checkInitializeUser(data[indextr])
                                        "
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="RefreshCcwIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Réinitialiser</span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              class="text-primary"
                                              icon="RefreshCcwIcon"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3"
                                              >Réinitialiser</span
                                            >
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>

                                  <div>
                                    <vs-dropdown-item
                                      v-if="checkUserRole('users').lock"
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="ChangeStatuts(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            :icon="
                                              tooltipIconText({
                                                status: data[indextr].status,
                                              }).icon
                                            "
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">
                                            {{
                                              tooltipIconText({
                                                status: data[indextr].status,
                                              }).text
                                            }}
                                          </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              :icon="
                                                tooltipIconText({
                                                  status: data[indextr].status,
                                                }).icon
                                              "
                                              class="text-primary"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">
                                              {{
                                                tooltipIconText({
                                                  status: data[indextr].status,
                                                }).text
                                              }}
                                            </span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>

                                  <div>
                                    <vs-dropdown-item
                                      v-if="checkUserRole('users').delete"
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="checkRemoveUser(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="Trash2Icon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Supprimer </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              icon="Trash2Icon"
                                              class="text-primary"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">Supprimer </span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>

                                  <div>
                                    <vs-dropdown-item
                                      v-if="checkUserRole('users').send"
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="sendMail(data[indextr].id)"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="SendIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Envoyer mail </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              icon="SendIcon"
                                              class="text-primary"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3"
                                              >Envoyer mail
                                            </span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>
                                </vs-dropdown-menu>
                              </vs-dropdown>

                              <vx-tooltip
                                position="left"
                                color="primary"
                                :title="aboutElement(data[indextr]).title"
                                :text="aboutElement(data[indextr]).text"
                              >
                                <div class="cursor-pointer ml-6">
                                  <feather-icon
                                    icon="InfoIcon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>

                              <div
                                class="ml-2"
                                v-show="woIsAction == data[indextr].id"
                              >
                                <clip-loader
                                  :loading="
                                    loadingRemoveUser ||
                                    loadingUpdateUser ||
                                    loadingInitUser ||
                                    loadingAllowUser
                                  "
                                  color="primary"
                                  size="25px"
                                ></clip-loader>
                              </div>
                            </div>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                    <vs-pagination
                      style="justify-content: flex-end !important"
                      class="mt-6 aa-vie-pagination"
                      :total="allPages"
                      v-model="currentPage"
                    ></vs-pagination>
                  </div>
                  <div v-else>
                    <div class="flex items-center justify-center mt-8">
                      <strong>Aucun enregistrement</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <vs-popup
              class="holamundo"
              title="Ajouter un administrateur"
              :active.sync="popupAddUserActive"
            >
              <div class="m-4">
                <div class="clearfix">
                  <div>
                    <vs-row vs-w="12">
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Profil*</span>
                          <multiselect
                            v-model="adminType"
                            :options="typeList"
                            placeholder="--Choisir--"
                            label="label"
                            track-by="label"
                            v-validate="'required'"
                            name="Profil"
                            required
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                            <template v-slot:noOptions>
                              <span>{{ global.selectNotFoundData }}</span>
                            </template>
                          </multiselect>

                          <div class="aab-vie-error-msg">
                            {{ errors.first("Profil") }}
                          </div>
                        </div>
                      </vs-col>

                      <vs-col
                        class="pr-4 mt-2 mb-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-input
                            class="inputx w-full mb-2 mt-2"
                            placeholder="Entrer votre nom"
                            label="Nom*"
                            v-model="form.lastname"
                            v-validate="'required|min:3'"
                            data-vv-validate-on="blur"
                            name="nom"
                          />
                          <div class="aab-vie-error-msg">
                            {{ errors.first("nom") }}
                          </div>
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-input
                            class="inputx w-full mb-2 mt-2"
                            placeholder="Entrer votre prénom(s)"
                            label="Prénom(s)*"
                            v-model="form.firstname"
                            v-validate="'required|min:3'"
                            data-vv-validate-on="blur"
                            name="prénom"
                          />
                          <div class="aab-vie-error-msg">
                            {{ errors.first("prénom") }}
                          </div>
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4 mt-2 mb-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-input
                            class="inputx w-full mb-2 mt-2"
                            placeholder="Entrer votre nom d'utilisateur"
                            label="Nom d'utilisateur*"
                            v-model="form.username"
                            v-validate="'required|min:3'"
                            data-vv-validate-on="blur"
                            name="nom d'utilisateur"
                          />
                          <div class="aab-vie-error-msg">
                            {{ errors.first("nom d'utilisateur") }}
                          </div>
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4 mt-2 mb-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-input
                            class="inputx w-full mb-2 mt-2"
                            placeholder="Entrer votre email"
                            label="Email*"
                            v-model="form.email"
                            v-validate="'required|email'"
                            data-vv-validate-on="blur"
                            type="email"
                            name="email"
                          />
                          <div class="aab-vie-error-msg">
                            {{ errors.first("email") }}
                          </div>
                        </div>
                      </vs-col>

                      <vs-col
                        class="pt-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="ml-2">Accès</span>
                          <vs-row>
                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAcces.isDisableAdministrators"
                                v-model="userAcces.chk_administrators"
                                >Administrateurs</vs-checkbox
                              >
                            </vs-col>
                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAcces.isDisableIntermediary"
                                v-model="userAcces.chk_intermediary"
                                >Intermédiaires</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAcces.isDisableCustomerClient"
                                v-model="userAcces.chk_customer_client"
                                >Comptes Clients</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAcces.isDisableProjectPending"
                                v-model="userAcces.chk_project_pending"
                                >Projets en cours</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAcces.isDisableSubscription"
                                v-model="userAcces.chk_subscription"
                                >Souscription</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAcces.isDisableMailing"
                                v-model="userAcces.chk_mailing"
                                >Mailing</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAcces.isDisableMailClient"
                                v-model="userAcces.chk_mail_client"
                                >Mails Clients</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAcces.isDisableSlip"
                                v-model="userAcces.chk_slip"
                                >Bordereaux</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAcces.isDisableTicket"
                                v-model="userAcces.chk_ticket"
                                >Tickets</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAcces.isDisableCustomer_docs"
                                v-model="userAcces.chk_customer_docs"
                                >Documents Clients</vs-checkbox
                              >
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
                <div class="mt-8">
                  <div class="inline-flex">
                    <div>
                      <vs-button
                        class="w-full sm:w-auto"
                        :disabled="!validateFormAddUser || loadingAddUser"
                        @click="fetchApplyAddUser"
                        >Valider</vs-button
                      >
                      <vs-button
                        class="w-full sm:w-auto ml-4"
                        type="border"
                        @click="popupAddUserActive = false"
                        >Annuler</vs-button
                      >
                    </div>
                    <div class="ml-2">
                      <clip-loader
                        :loading="loadingAddUser"
                        color="primary"
                      ></clip-loader>
                    </div>
                  </div>
                  <div class="mt-6">
                    <p class="text-danger">* Champ obligatoire</p>
                  </div>
                </div>
              </div>
            </vs-popup>

            <vs-popup
              class="holamundo"
              title="Modifier un administrateur"
              :active.sync="popupUpdateUserActive"
            >
              <div class="m-4">
                <div class="clearfix">
                  <div>
                    <vs-row vs-w="12">
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Profil*</span>
                          <multiselect
                            v-model="adminTypeUpdated"
                            :options="typeList"
                            placeholder="--Choisir--"
                            label="label"
                            track-by="label"
                            v-validate="'required'"
                            name="Profil"
                            required
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                            <template v-slot:noOptions>
                              <span>{{ global.selectNotFoundData }}</span>
                            </template>
                          </multiselect>

                          <div class="aab-vie-error-msg">
                            {{ errors.first("Profil") }}
                          </div>
                        </div>
                      </vs-col>

                      <vs-col
                        class="pr-4 mt-2 mb-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-input
                            class="inputx w-full mb-2 mt-2"
                            placeholder="Entrer votre nom"
                            label="Nom*"
                            v-model="formUpdated.lastname"
                            v-validate="'required|min:3'"
                            data-vv-validate-on="blur"
                            name="nom"
                          />
                          <div class="aab-vie-error-msg">
                            {{ errors.first("nom") }}
                          </div>
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4 mt-2 mb-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-input
                            class="inputx w-full mb-2 mt-2"
                            placeholder="Entrer votre prénom(s)"
                            label="Prénom(s)*"
                            v-model="formUpdated.firstname"
                            v-validate="'required|min:3'"
                            data-vv-validate-on="blur"
                            name="prénom"
                          />
                          <div class="aab-vie-error-msg">
                            {{ errors.first("prénom") }}
                          </div>
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4 mt-2 mb-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-input
                            class="inputx w-full mb-2 mt-2"
                            placeholder="Entrer votre nom d'utilisateur"
                            label="Nom d'utilisateur*"
                            v-model="formUpdated.username"
                            disabled
                            v-validate="'required|min:3'"
                            data-vv-validate-on="blur"
                            name="nom d'utilisateur"
                          />
                          <div class="aab-vie-error-msg">
                            {{ errors.first("nom d'utilisateur") }}
                          </div>
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4 mt-2 mb-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-input
                            class="inputx w-full mb-2 mt-2"
                            placeholder="Entrer votre email"
                            label="Email*"
                            v-model="formUpdated.email"
                            v-validate="'required|email'"
                            data-vv-validate-on="blur"
                            type="email"
                            name="email"
                          />
                          <div class="aab-vie-error-msg">
                            {{ errors.first("email") }}
                          </div>
                        </div>
                      </vs-col>

                      <vs-col
                        class="pt-3 pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="ml-2">Accès</span>
                          <vs-row>
                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="
                                  userAccesUpdated.isDisableAdministrators
                                "
                                v-model="userAccesUpdated.chk_administrators"
                                >Administrateurs</vs-checkbox
                              >
                            </vs-col>
                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="
                                  userAccesUpdated.isDisableIntermediary
                                "
                                v-model="userAccesUpdated.chk_intermediary"
                                >Intermédiaires</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="
                                  userAccesUpdated.isDisableCustomerClient
                                "
                                v-model="userAccesUpdated.chk_customer_client"
                                >Comptes Clients</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="
                                  userAccesUpdated.isDisableProjectPending
                                "
                                v-model="userAccesUpdated.chk_project_pending"
                                >Projets en cours</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="
                                  userAccesUpdated.isDisableSubscription
                                "
                                v-model="userAccesUpdated.chk_subscription"
                                >Souscription</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAccesUpdated.isDisableMailing"
                                v-model="userAccesUpdated.chk_mailing"
                                >Mailing</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAccesUpdated.isDisableMailClient"
                                v-model="userAccesUpdated.chk_mail_client"
                                >Mails Clients</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAccesUpdated.isDisableSlip"
                                v-model="userAccesUpdated.chk_slip"
                                >Bordereaux</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="userAccesUpdated.isDisableTicket"
                                v-model="userAccesUpdated.chk_ticket"
                                >Tickets</vs-checkbox
                              >
                            </vs-col>

                            <vs-col
                              class="pt-2 pb-2"
                              vs-lg="4"
                              vs-sm="4"
                              vs-xs="12"
                            >
                              <vs-checkbox
                                :disabled="
                                  userAccesUpdated.isDisableCustomer_docs
                                "
                                v-model="userAccesUpdated.chk_customer_docs"
                                >Documents Clients</vs-checkbox
                              >
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
                <div class="mt-8">
                  <div class="inline-flex">
                    <div>
                      <vs-button
                        class="w-full sm:w-auto"
                        :disabled="!validateFormUpdateUser || loadingUpdateUser"
                        @click="fetchApplyUpdateUser"
                        >Valider</vs-button
                      >
                      <vs-button
                        class="w-full sm:w-auto ml-4"
                        type="border"
                        @click="popupUpdateUserActive = false"
                        >Annuler</vs-button
                      >
                    </div>
                    <div class="ml-2">
                      <clip-loader
                        :loading="loadingUpdateUser"
                        color="primary"
                      ></clip-loader>
                    </div>
                  </div>
                  <div class="mt-6">
                    <p class="text-danger">* Champ obligatoire</p>
                  </div>
                </div>
              </div>
            </vs-popup>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      userAcces: {},
      userAccesUpdated: {},
      adminType: "",
      adminTypeUpdated: "",

      accesList: [
        { visibility: false, id: "mailing", label: "WebMail" },
        { visibility: false, id: "broker", label: "Courtier" },
        { visibility: false, id: "offer", label: "Offre" },
        { visibility: false, id: "subscription", label: "Souscription" },
      ],
      typeList: [
        { id: "administrator_sys", label: "Administrateur" },
        { id: "gestionnaire_sys", label: "Gestionnnaire" },
        { id: "medical_advisor_sys", label: "Médecin-Conseil" },
      ],
      role: {},
      global: {},
      display: false,
      form: {
        email: "",
        firstname: "",
        username: "",
        lastname: "",
        type: "",
        userAcces: {},
      },
      usernameInit: "",
      allowStatus: "",
      formUpdated: {
        email: "",
        firstname: "",
        lastname: "",
        type: "",
        userAcces: {},
      },
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      loading: false,
      loadingRemoveUser: false,
      loadingUpdateUser: false,
      loadingInitUser: false,
      loadingAddUser: false,
      loadingAllowUser: false,
      error: false,
      errorMsg: "",
      crrID: "",
      woIsAction: "",
      type: ["administrator_sys", "gestionnaire_sys", "medical_advisor_sys"],
      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"], //formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "N°",
        "Profil",
        "Prénom(s)",
        "Nom",
        "Nom d'utilisateur",
        "Email",
        "Statut",
        "Informations",
      ],
      headerVal: [
        "index",
        "type",
        "firstname",
        "lastname",
        "username",
        "email",
        "status",
        "infos",
      ],
      activePrompt: false,
      //end export section
      popupAddUserActive: false,
      popupUpdateUserActive: false,
      userSelected: [],
      crrIDs: [],
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.global = global;
  },
  mounted() {
    if (
      this.user.type != "super_administrator_sys" &&
      !this.user.person.userAcces.chk_intermediary
    ) {
      if (!this.checkUserRole("users").all) this.$router.push({ name: "Home" });
    }

    this.userAcces = this.allUserAcces;
    this.userAccesUpdated = this.allUserAcces;

    if (this.user.type == "super_administrator_sys") {
      this.typeList = [
        { id: "administrator_sys", label: "Administrateur" },
        { id: "gestionnaire_sys", label: "Gestionnnaire" },
        { id: "medical_advisor_sys", label: "Médecin-Conseil" },
      ];
    } else if (this.user.type == "administrator_sys") {
      this.typeList = [
        { id: "gestionnaire_sys", label: "Gestionnnaire" },
        { id: "medical_advisor_sys", label: "Médecin-Conseil" },
      ];
    }
    this.limit = this.rowsTable[1];

  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("users", ["allUsers"]),
    ...mapGetters("general", ["rowsTable", "allUserAcces", "allUserTypes"]),
    validateFormAddUser() {
      return (
        !this.errors.any() &&
        this.form.email != "" &&
        this.form.firstname != "" &&
        this.form.username != "" &&
        this.form.lastname != "" &&
        this.form.type != ""
      );
    },
    validateFormUpdateUser() {
      return (
        !this.errors.any() &&
        this.formUpdated.email != "" &&
        this.formUpdated.firstname != "" &&
        this.formUpdated.lastname != "" &&
        this.formUpdated.type != ""
      );
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("users", [
      "applyGetUserSys",
      "applyGetAllUsersSys",
      "applyAddUserSys",
      "applyPutUserSys",
      "applyRemoveUserSys",
      "applyAllowUserSys",
      "applyInitializeUserSys",
    ]),
    tooltipIconText(data) {
      // "Activer / Désactiver"
      if (Object.keys(data) == "status") {
        return data.status === "inactive"
          ? { text: "Activer", icon: "LockIcon" }
          : { text: "Désactiver", icon: "UnlockIcon" };
      } else if (Object.keys(data) == "mask") {
        return data.isVisible
          ? { text: "Masquer", icon: "EyeOffIcon" }
          : { text: "Démasquer", icon: "EyeIcon" };
      }
    },
    byStatusUserText(status) {
      // "Activer / Désactiver"
      return status === "inactive" ? "Désactiver" : "Activer";
    },
    displayType(profil) {
      let x = [
        { id: "administrator_sys", label: "Administrateur" },
        { id: "gestionnaire_sys", label: "Gestionnnaire" },
        { id: "medical_advisor_sys", label: "Médecin-Conseil" },
      ];

      return x.find((type) => type.id == profil).label;
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    adminSelectedDouble(item) {
      if (this.checkUserRole("users").show) {
        this.sendMail(item.id);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allUsers.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            type: this.typeList.find((type) => type.id == el.type).label,
            firstname: el.person.firstname,
            lastname: el.person.lastname,
            username: el.username,
            email: el.email,
            status: this.byStatusUserText(el.status),
            infos: this.aboutElement(el).text,
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES ADMINISTRATEURS"
        );
      } else {
        let tableToPrint = [];

        this.allUsers.list.forEach((el) => {
          let jsonTmp = {
            type: this.typeList.find((type) => type.id == el.type).label,
            firstname: el.person.firstname,
            lastname: el.person.lastname,
            username: el.username,
            email: el.email,
            status: this.byStatusUserText(el.status),
            infos: this.aboutElement(el).text,
            userAccess: this.formatUserAcces(el.person.userAcces),
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [
          "type",
          "firstname",
          "lastname",
          "username",
          "email",
          "status",
          "userAccess",
          "infos",
        ];

        let headerTitleTmp = [
          "Profil",
          "Prénom(s)",
          "Nom",
          "Nom d'utilisateur",
          "Email",
          "Statut",
          "Accès",
          "Informations",
        ];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },

    formatUserAcces(data) {
      let accessWithLabel = {
        chk_administrators: "Administrateurs",
        chk_customer_client: "Comptes Clients",
        chk_customer_docs: "Documents Clients",
        chk_intermediary: "Intermédiaires",
        chk_mail_client: "Mails Clients",
        chk_mailing: "Mailing",
        chk_project_pending: "Projets en cours",
        chk_slip: "Bordereaux",
        chk_subscription: "Souscription",
        chk_ticket: "Tickets",
      };
      let usersAccs = [];
      for (const [key, value] of Object.entries(data)) {
        if (accessWithLabel[key]) {
          usersAccs.push({
            key: key,
            value: value,
            label: accessWithLabel[key],
          });
        }
      }
      return usersAccs
        .filter((user) => user.value == true)
        .map((acc) => acc.label)
        .join(", ");
    },

    sendMail(item) {
      console.log(item);
      setTimeout(() => {
        this.$router.push({
          name: "MailReceived",
          params: { userId: item, crrModuleName: "Users" },
        });
      }, 100);
    },
    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    async fetchApplyGetAllUsers(data) {
      this.loading = true;

      try {
        await this.applyGetAllUsersSys(data);

        this.total = this.allUsers.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            ); 
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Administrateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Administrateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async fetchApplyAddUser() {
      if (!this.validateFormAddUser) return false;

      let data = {
        ...this.form,
        type: this.form.type ? this.form.type.id : "",
        userAcces: this.userAcces,
      };
      this.loadingAddUser = true;

      try {
        await this.applyAddUserSys(data);
        this.loadingAddUser = false;
        this.acceptAlert(mixin.methods.message("users", "add").success);

        this.form = {};
        this.userAcces = {}
        this.popupAddUserActive = false;
      } catch (err) {
        this.loadingAddUser = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupAddUserActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.acceptAlert(
                    mixin.methods.message("users", "notFound").error
                  );
                  break;
                case "userNameExistError":
                  this.acceptAlert(
                    mixin.methods.message("users", "nameExist").error
                  );
                  break;
                case "sendMailError":
                  this.acceptAlert(
                    mixin.methods.message("users", "mailError").error
                  );
                  break;

                default:
                  this.acceptAlert(mixin.methods.message("users", "add").error);
                  break;
              }
            });
          }
        }
      }
    },
    checkUpdateUser(item) {
      this.crrID = item.id;
      console.log(
        item,
        this.typeList.find((el) => el.id == item.type)
      );
      this.userAccesUpdated = { ...item.userAcces };
      this.formUpdated = { ...item.person, ...item };
      this.adminTypeUpdated = this.typeList.find(
        (el) => el.id == this.formUpdated.type
      );
      this.popupUpdateUserActive = true;
    },
    async fetchApplyUpdateUser() {
      if (!this.validateFormUpdateUser) return false;

      let data = {
        userId: this.crrID,
        ...this.formUpdated,
        type: this.formUpdated.type.id,
        userAcces: this.userAccesUpdated,
      };
      this.loadingUpdateUser = true;

      try {
        await this.applyPutUserSys(data);

        this.acceptAlert(mixin.methods.message("users", "update").success);

        this.formUpdated = {};
        this.userAccesUpdated = {}
        this.loadingUpdateUser = false;
        this.popupUpdateUserActive = false;
      } catch (err) {
        this.loadingUpdateUser = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupUpdateUserActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.acceptAlert(
                    mixin.methods.message("broker", "notFound").error
                  );
                  break;
                case "userNameExistError":
                  this.acceptAlert(
                    mixin.methods.message("broker", "nameExist").error
                  );
                  break;
                case "sendMailError":
                  this.acceptAlert(
                    mixin.methods.message("broker", "mailError").error
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("users", "update").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkRemoveMultipleUser() {
      this.userSelected.forEach((element) => {
        this.crrIDs.push(element.id);
      });
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer les éléments sélectionnés ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchRemoveMultipleUsers,
      });
    },
    async fetchRemoveMultipleUsers() {
      let data = {
        ids: this.crrIDs,
      };

      this.loading = true;
      try {
        await this.applyRemoveUserSys(data);
        this.loading = false;
        this.acceptAlert(
          mixin.methods.message("users", "multipleDeletion").success
        );

        this.crrIDs = [];
        this.userSelected = [];
      } catch (err) {
        this.loading = false;
        this.crrIDs = [];
        this.userSelected = [];

        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    mixin.methods.message("users", "multipleDeletion").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkSentPublipostage() {
      let emailList = [];
      this.userSelected.forEach((user) => {
        emailList.push(user.email);
      });

      this.$router.push({
        name: "Mailing",
        query: { name: "users", data: emailList },
      });
    },
    checkRemoveUser(user) {
      this.crrID = user.id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer ${user.person.lastname} ${user.person.firstname} ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveUser,
      });
    },
    async fetchApplyRemoveUser() {
      this.woIsAction = this.crrID;
      let data = {
        ids: [this.crrID],
      };
      this.loadingRemoveUser = true;
      try {
        await this.applyRemoveUserSys(data);
        this.loadingRemoveUser = false;
        this.acceptAlert(mixin.methods.message("users", "delete").success);
        this.woIsAction = "";
        this.userSelected = [];
      } catch (err) {
        this.loadingRemoveUser = false;
        this.woIsAction = "";
        this.userSelected = [];
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Administrateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("users", "delete").error
                  );
                  break;
              }
            });
          }
        }
      }
    },
    checkInitializeUser(item) {
      this.crrID = item.id;
      this.usernameInit = item.username;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment réinitialiser le mot de passe de ${item.person.lastname} ${item.person.firstname} ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyInitializeUser,
      });
    },
    async fetchApplyInitializeUser() {
      this.woIsAction = this.crrID;
      let data = {
        username: this.usernameInit,
      };

      this.loadingInitUser = true;
      try {
        await this.applyInitializeUserSys(data);
        this.loadingInitUser = false;

        this.acceptAlert(
          mixin.methods.message("users", "reinitialise").success
        );

        this.woIsAction = "";
      } catch (err) {
        this.loadingInitUser = false;
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    mixin.methods.message("users", "reinitialise").error
                  );

                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Mot de passe",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    ChangeStatuts(item) {
      this.crrID = item.id;
      let text = "";

      if (item.status === "inactive") {
        this.allowStatus = "active";
        text = `Voulez-vous vraiment activer ${item.person.lastname} ${item.person.firstname} ?`;
      } else {
        this.allowStatus = "inactive";
        text = `Voulez-vous vraiment désactiver ${item.person.lastname} ${item.person.firstname} ?`;
      }
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyAllowUser,
      });
    },
    async fetchApplyAllowUser() {
      this.woIsAction = this.crrID;
      let data = {
        userId: this.crrID,
        status: this.allowStatus,
      };
      let text = {};
      if (this.allowStatus === "inactive") {
        text = mixin.methods.message("users", "desactive").success;
      } else {
        text = mixin.methods.message("users", "active").success;
      }

      this.loadingAllowUser = true;
      try {
        await this.applyAllowUserSys(data);
        this.loadingAllowUser = false;
        this.acceptAlert(text);
        this.woIsAction = "";
      } catch (err) {
        this.loadingAllowUser = false;
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Administrateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
  },
  watch: {
    userAcces() {
      this.form.userAcces = this.userAcces;
    },
    adminType() {
      this.form.type = this.adminType;
      switch (this.adminType.id) {
        case "gestionnaire_sys":
          this.userAcces.chk_administrators = false;
          this.userAcces.chk_mailing = false;
          this.userAcces.isDisableAdministrators = true;
          this.userAcces.isDisableMailing = true;

          break;
        case "medical_advisor_sys":
          this.userAcces.chk_administrators = false;
          this.userAcces.chk_mailing = false;
          this.userAcces.isDisableAdministrators = true;
          this.userAcces.isDisableMailing = true;

          break;

        default:
          this.userAcces.isDisableAdministrators = false;
          this.userAcces.isDisableMailing = false;

          break;
      }
    },
    userAccesUpdated() {
      console.log(this.userAccesUpdated);
      this.formUpdated.userAcces = this.userAccesUpdated;
    },
    adminTypeUpdated() {
      this.formUpdated.type = this.adminTypeUpdated;
      let userAccesTmp = this.userAccesUpdated;
      switch (this.adminTypeUpdated.id) {
        case "gestionnaire_sys":
          this.userAccesUpdated.chk_administrators = false;
          this.userAccesUpdated.chk_mailing = false;
          this.userAccesUpdated.isDisableAdministrators = true;
          this.userAccesUpdated.isDisableMailing = true;

          break;
        case "medical_advisor_sys":
          this.userAccesUpdated.chk_administrators = false;
          this.userAccesUpdated.chk_mailing = false;
          this.userAccesUpdated.isDisableAdministrators = true;
          this.userAccesUpdated.isDisableMailing = true;

          break;

        default:
          this.userAccesUpdated.isDisableAdministrators = false;
          this.userAccesUpdated.isDisableMailing = false;

          break;
      }
    },
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchApplyGetAllUsers({
        limit: this.limit,
        skip: this.skip,
        type: this.type,
      });
    },
    limit() {
      this.skip = 0;

      this.fetchApplyGetAllUsers({
        limit: this.limit,
        skip: this.skip,
        type: this.type,
      });
    },
  },
  components: {
    ClipLoader,
    Multiselect,
  },
};
</script>

<style lang="scss">
.aab-vie-icon-clickable {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}
</style>
